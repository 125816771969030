import React from "react";
import Above from "../components/global/GenericBanner";
import Layout from "../components/global/Layout";
import Apply from "../components/global/forms/Apply";
import { parseSeoFromQuery } from "../components/global/SEO";
import { graphql } from "gatsby";

const ApplyPage = props => {
  console.log(props);
  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <Above {...props?.data?.markdownRemark?.frontmatter} />
      <Apply />
    </Layout>
  );
};

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        whiteTitle
        greenTitle
      }
    }
  }
`;

export default ApplyPage;
