import React, {useEffect} from 'react'
import useCountryAndLocation from "../../../utils/use-country-location";
import {isGtmEnabled} from "../../../utils/google-tag-manager";

const buildLocale = (country, language) => {
    if (country.toLocaleLowerCase() === 'uk') {
        return "en-GB";
    }
    if (country.toLocaleLowerCase() === 'nl') {
        return "nl";
    }

    return `${language.toLocaleLowerCase()}-${country.toLocaleUpperCase()}`;
}

const ApplyForm = () => {

    const {country, language, tenant} = useCountryAndLocation();

    const locale = buildLocale(country, language);

    useEffect(() => {
        const root =
            document.querySelectorAll('script')[0] ?? document.head.lastChild;

        const applicantFormScript = document.createElement('script');
        applicantFormScript.type = 'text/javascript';
        applicantFormScript.id = 'applicant-form-script-tag';
        applicantFormScript.src = 'https://apply.tp24group.com/applicant-form-group/applicant-form.min.js';
        applicantFormScript.defer = true;
        if (root) {
            root.parentNode.insertBefore(applicantFormScript, root);
        } else {
            document.head.appendChild(applicantFormScript);
        }

        return () => {
            const script = document.querySelector("#applicant-form-script-tag");
            const parent = script.parentElement
            parent.removeChild(script);
            script.remove();
        }
    }, [])

    return (
        <section className="container apply-area">
            <div className="applicant-form">
                <applicant-form tenant={tenant} locale={locale} country={country} cookies-enabled={isGtmEnabled()} google-analytics-id={"G-XKBPF4CBEM"}></applicant-form>
            </div>
        </section>
    )
}

export default ApplyForm